.react-datepicker {
	@apply bg-white shadow border-light-100 w-full h-full;

	&__triangle {
		@apply hidden;
	}

	&__header {
		@apply border-light-100 shadow-md rounded-br rounded-bl bg-primary-500 text-white;

		&__dropdown {
			@apply flex items-center justify-center space-x-8;
		}
	}

	&__current-month {
		@apply flex items-center justify-center uppercase text-sm font-semibold text-white;
	}
	&__navigation {
		@apply w-8 h-8 flex items-center justify-center;

		&-icon {
			@apply w-full h-full flex items-center justify-center;

			&::before {
				@apply hidden;
			}
		}
	}

	&__input-container {
		input {
			@apply block w-full text-sm font-semibold placeholder-light-200 focus:outline-none bg-transparent border-0 focus:ring-0 pb-1 pl-0;
		}
	}

	&__tab-loop {
		@apply bg-white bg-opacity-30;
	}

	&-popper {
		@apply z-10 text-xs md:text-sm transform-none px-3 py-2;
	}

	&__month-container {
		@apply flex flex-col;
	}

	&__month {
		@apply flex flex-col;
	}

	&__week {
		@apply flex justify-around;
	}

	&__day-names {
		@apply flex justify-around text-gray-800 font-medium text-center text-xs
	}

	&__day-name {
		@apply w-8 h-8 text-white  flex items-center justify-center py-1 rounded-full;
	}

	&__day {
		@apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-black rounded hover:bg-primary-200;

		&--selected {
			@apply bg-primary-500 text-white;
		}

		&--keyboard-selected {
			@apply bg-primary-300 text-black;
		}

		&--disabled {
			@apply cursor-not-allowed opacity-50 hover:bg-transparent;
		}
	
		&--outside-month {
			@apply text-light-500 hover:bg-light-100;
		}
	
		&--in-range {
			@apply bg-gray-200;
		}
	
		&--in-selecting-range {
			@apply bg-blue-200;
		}
	
		&--selecting-range-start {
			@apply bg-white border-2 border-blue-500;
		}
	
		&--selecting-range-end {
			@apply bg-white border-2 border-blue-500;
		}

		&--range-start {
			@apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
		}
	
		&--range-end {
			@apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
		}
	}
}
